@import url('https://fonts.googleapis.com/css2?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Lato');
* {
  box-sizing: border-box;
  position: relative;
}

body {
    height: 100vh;
    width: 100vw;
    margin: 0;
	background-color: #000;
	color: #fff;
	font-family: Monospace;
	font-size: 13px;
	line-height: 24px;
	overscroll-behavior: none;
}


#myVideo {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%; 
    min-height: 100%;
  }

.heading {
  position: absolute;
  width: 50%;
  font-family: 'Oswald';
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
  color: white;
  z-index: 100;
}

.headingLeft {
  position: absolute;
  top: 25%;
  width: 30%;
  font-family: 'Oswald';
  font-size: 120px;
  text-align: center;
  letter-spacing: 5px;
  color: white;
  z-index: 100;
}


.content {
  position: absolute;
  top: 25%;
  width: 40%;
  font-family: 'Lato';
  font-size: 20px;
  text-align: justify;
  letter-spacing: 3px;
  word-spacing: 7px;
  line-height: 1.5;
  color: white;
  z-index: 2;
}


.webgl{
    position:fixed;
    top: 0;
    left: 0;
}


a {
	color: #ff0;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

button {
	cursor: pointer;
	text-transform: uppercase;
}

#info {
	position: absolute;
	top: 0px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 1; /* TODO Solve this in HTML */
}

a, button, input, select {
	pointer-events: auto;
}

.lil-gui {
	z-index: 2 !important; /* TODO Solve this in HTML */
}

@media all and ( max-width: 640px ) {
	.lil-gui.root { 
		right: auto;
		top: auto;
		max-height: 50%;
		max-width: 80%;
		bottom: 0;
		left: 0;
	}
}

#overlay {
	position: absolute;
	font-size: 16px;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: rgba(0,0,0,0.7);
}

	#overlay button {
		background: transparent;
		border: 0;
		border: 1px solid rgb(255, 255, 255);
		border-radius: 4px;
		color: #ffffff;
		padding: 12px 18px;
		text-transform: uppercase;
		cursor: pointer;
	}

#notSupported {
	width: 50%;
	margin: auto;
	background-color: #f00;
	margin-top: 20px;
	padding: 10px;
}